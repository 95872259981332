import { NavigationContent } from './../../../business/common/model/navigation-content';
import { NavigationService } from './../../../business/api/navigation.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { debounce, distinctUntilChanged } from 'rxjs/operators';
import { isDesktop, isMobile } from 'src/app/shared/layout/functions';
import { LocalizationService } from 'src/app/business/common/localization.service';

@Component({
    selector: 'riv-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    private windowWidth: any;
    private mobileResolution = 768;
    private desktopResolution = 950;

    itemTargets: string[][][] = [];

    menuStateChange = new Subject<any>();
    menuStateChangeDebounce: number;
    mouseOverMenuBar = false;
    mouseOverMenuList = false;

    navigationData: NavigationContent[];
    currentSubMenu: number = 0;
    isSubmenuOpen: boolean = false;
    fullMenuIsOpen = false;

    isMobile = true;
    isDesktop = false;
    locale;
    constructor(private navigationService: NavigationService, private localizationService: LocalizationService) { }

    ngOnInit(): void {
        this.menuStateChange.pipe(
            debounce(() => timer(this.menuStateChangeDebounce)),
            distinctUntilChanged())
            .subscribe(value => {
                this.toggleMenu(value.index, value.trigger);
            });
        this.navigationService.getNavigation('header-navigation').subscribe(result => {
            this.navigationData = result;
            this.precomputeTargets();
        });
        this.getScreenSize();
        this.locale = this.localizationService.currentLanguage.code;
    }
    
    precomputeTargets() {
        this.itemTargets = this.navigationData.map(menu =>
            menu.items.map(column =>
                column.items.map(row =>
                    row.audience?.includes('external-same-tab') ? null : '_blank'
                )
            )
        );
    }

    menuStateNextValue(index, trigger) {
        if (trigger == "mouseOverMenuBar" || trigger == "linkClick") {
            this.menuStateChangeDebounce = 0;
        } else {
            this.menuStateChangeDebounce = 250;
        }
        this.menuStateChange.next({ index: index, trigger: trigger });
    }

    openFullMenu() {
        const htmlSelector = this.getHtmlSelector();

        this.fullMenuIsOpen = !this.fullMenuIsOpen

        if (this.fullMenuIsOpen) {
            htmlSelector.classList.add('no-scroll')
        } else {
            htmlSelector.classList.remove('no-scroll')
        }

    }

    @HostListener('window:resize', ['$event'])
    private getScreenSize() {
        this.isMobile = isMobile();

        this.isDesktop = isDesktop();
    }

    private getHtmlSelector() {
        return document.querySelector('html') as HTMLElement;
    }

    toggleMenu(index: number, trigger: string) {
        switch (trigger) {
            case "mouseOverMenuBar":
                let menuItemArrowLeft = document.getElementById("menuItemArrow" + index).offsetLeft;
                document.getElementById("sub-menu-arrow").style.left = menuItemArrowLeft - 17 + "px";
                this.isSubmenuOpen = true;
                this.currentSubMenu = index;
            break;
            case "mouseOutMenuBar":
            case "mouseOutMenuList":
            case "linkClick":
                this.isSubmenuOpen = false;
            break;
            case "mouseOverMenuList":
                this.isSubmenuOpen = true;
            break;
            default:
            break;
        }
    }

    closeMenu() {
        this.isSubmenuOpen = false;
    }

    closeFullMenu() {
        this.fullMenuIsOpen = false;
    }
}
