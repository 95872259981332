<header>
    <riv-language-bar *ngIf="!isMobile"></riv-language-bar>

    <div class="header">
        <div class="container-grid">
            <nav>
                <h1>
                    <a routerLink="/">
                        <img [src]="locale === 'fr' ? '../assets/img/riv-logo-fr.svg' : '../assets/img/riv-logo.svg'"
                             alt="RIV Logo"
                             width="180"
                             height="45">
                    </a>
                </h1>

                <ul *ngIf="isMobile || !isDesktop"
                    class="main-navigation">
                    <li class="main-navigation__item">
                        <button class="nav-toggle"
                                [ngClass]="{'open':  fullMenuIsOpen }"
                                (click)="openFullMenu()">
                            <span class="hamburger"></span>
                            <span class="sr-only">Menu</span>
                        </button>
                    </li>
                </ul>

                <ul *ngIf="!isMobile && isDesktop"
                    class="main-navigation">
                    <li *ngFor="let menuItem of navigationData; index as i;">
                        <div *ngIf="menuItem.audience && menuItem.audience.includes('dropdown-item')"
                             class="main-navigation__item"
                             (mouseover)="menuStateNextValue(i, 'mouseOverMenuBar')"
                             (mouseout)="menuStateNextValue(i, 'mouseOutMenuBar')"
                             [ngClass]="{'active':  currentSubMenu == i && isSubmenuOpen }">
                            <div class="item arrow">
                                <a [routerLink]="menuItem.path"
                                   (click)="menuStateNextValue(i, 'linkClick')"> <span>{{menuItem.title }}</span> </a>
                                <div [id]="'menuItemArrow' + i"
                                     class="chevron"></div>
                            </div>
                        </div>
                        <div *ngIf="menuItem.audience && menuItem.audience.includes('dropdown-primary')"
                             class="main-navigation__item item--primary"
                             (mouseover)="menuStateNextValue(i, 'mouseOverMenuBar')"
                             (mouseout)="menuStateNextValue(i, 'mouseOutMenuBar')"
                             [ngClass]="{'active':  currentSubMenu == i && isSubmenuOpen }">
                            <div class="item arrow">
                                <a [routerLink]="menuItem.path"
                                   (click)="menuStateNextValue(i, 'linkClick')"> <span>{{menuItem.title }}</span> </a>
                                <div [id]="'menuItemArrow' + i"
                                     class="chevron"></div>
                            </div>
                        </div>
                        <a [routerLink]="menuItem.path">
                            <riv-button *ngIf="menuItem.audience && menuItem.audience.includes('secondary-button')"
                                        [buttonType]="'secondaryButton'"
                                        [text]="menuItem.title"></riv-button>

                            <riv-button *ngIf="menuItem.audience && menuItem.audience.includes('primary-button')"
                                        [buttonType]="'primaryButton'"
                                        [text]="menuItem.title"></riv-button>
                        </a>
                    </li>

                    <div *ngIf="navigationData"
                         class="item__sub-menu"
                         id="sub-menu"
                         (mouseover)="menuStateNextValue(0, 'mouseOverMenuList')"
                         (mouseout)="menuStateNextValue(0, 'mouseOutMenuList')"
                         [ngClass]="{'open':  isSubmenuOpen, 'last-submenu': currentSubMenu === navigationData.length - 1 }">
                        <div id="sub-menu-arrow"
                             class="sub-menu__arrow">
                        </div>
                        <div *ngFor="let column of navigationData[currentSubMenu].items; let columnIndex = index"
                             class="column">
                            <ul>
                                <li *ngFor="let row of column.items; let first = first; let rowIndex = index">
                                    <ng-container *ngIf="!row.external; else externalLink">
                                        <a [ngClass]="{'column-header': first }"
                                           [routerLink]="row.path"
                                           class="sub-item">
                                            <span>{{row.title}}</span>
                                        </a>
                                    </ng-container>
                                    <ng-template #externalLink>
                                        <a [ngClass]="{'column-header': first }"
                                           [href]="row.path" 
                                           [attr.target]="itemTargets[currentSubMenu][columnIndex][rowIndex]"
                                           class="sub-item">
                                            <span>{{row.title}}</span>
                                        </a>
                                    </ng-template>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <li class="main-navigation__item">
                        <button class="nav-toggle"
                                [ngClass]="{'open':  fullMenuIsOpen }"
                                (click)="openFullMenu()">
                            <span class="hamburger"></span>
                            <span class="sr-only">Menu</span>
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <riv-menu *ngIf="!isMobile"
              [ngClass]="{'open':  fullMenuIsOpen }"
              (onClosed)="closeFullMenu()"></riv-menu>

    <riv-mobile-menu *ngIf="isMobile"
                     [ngClass]="{'open':  fullMenuIsOpen }"
                     (onClosed)="closeFullMenu()">
    </riv-mobile-menu>
</header>
