import { LocalizationService } from './../../../business/common/localization.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/business/api/navigation.service';
import { NavigationContent } from 'src/app/business/common/model/navigation-content';

@Component({
    selector: 'riv-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

    navigationData: NavigationContent[];
    pageData: any;
    selectedLanguage = "";
    itemClasses: { [key: string]: boolean }[][][] = [];
    itemTargets: string[][][] = [];

    constructor(
        private navigationService: NavigationService,
        private localizationService: LocalizationService
    ) { }

    ngOnInit(): void {
        this.selectedLanguage = this.localizationService.currentLanguage.displayName;
        this.navigationService.getNavigation('footer-full-menu-navigation').subscribe(result => {
            this.navigationData = result;
            this.precomputeClasses();
            this.precomputeTargets();
        });
        this.navigationService.getFooterData().subscribe(result => {
            this.pageData = result;
        });
    }

    toggleLanguage(language) {
        this.localizationService.changeLanguageByName(language);
    }

    precomputeClasses() {
        this.itemClasses = this.navigationData?.map(column =>
            column.items.map(columnSection =>
                columnSection.items.map(item => ({
                    'menu__item': !item.audience || item.audience.includes('language-button') || item.audience.includes('secondary-alt-button'),
                    'menu__title': item.audience?.includes('column-header-link'),
                    'secondary-alt': item.audience?.includes('secondary-alt-button'),
                }))
            )
        );
    }

    precomputeTargets() {
        this.itemTargets = this.navigationData.map(column =>
            column.items.map(columnSection =>
                columnSection.items.map(item =>
                    item.audience?.includes('external-same-tab') ? null : '_blank'
                )
            )
        );
    }
}
